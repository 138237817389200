import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import AuthManager from "../secu/AuthManager";
import ClientService from "../Services/ClientService";
import Field from "../forms/mini/Field";


export default function ClientForm(props) {
    let service = new ClientService();
    let params = useParams();
    useEffect(() => {
        if (params && params.id) {
            service.getOne(params.id).then(json => {
                if (json.success) {
                    setClient(json.data);
                }
            })
        }
    }, [])

    let submitForm = function () {
        if (params && params.id) {
            service.update(client).then(json => {
                if (json.success) {
                    console.log(json)
                    navigate("/clients");
                }
            })
        } else {
            fetch("/api/client", {
                method: "POST",
                body: JSON.stringify(client),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                })
                .then(json => {
                    navigate("/clients");
                })
                .catch(err => {
                    console.error(err)
                });
        }


    }
    const navigate = useNavigate();
    let [client, setClient] = useState({});

    return <div className={"grid grid-cols-2 gap-4 divide-x divide-gray-200 border-b-2 border-gray-200"}>
        <div role="Form" className={"col-span-2"}>
            <form className="flex flex-col justify-center p-10">
                <div className="flex flex-wrap -mx-3 mb-6">

                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-denomination-sociale">
                            Denomination Social du client
                        </label>
                        <Field
                            data-cy="Client-denominationSocial"

                            value={client.denominationSocial || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.denominationSocial = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-denomination-sociale" type="text" placeholder="ex: Citeo" />
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            RCS
                        </label>
                        <input
                            data-cy="Client-rcs"

                            value={client.rcs || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.rcs = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: Ville 000 000 000" />
                    </div>


                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            Siret
                        </label>
                        <input
                            data-cy="Client-siret"

                            value={client.siret || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.siret = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: 84074430400022" />
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            President
                        </label>
                        <input
                            data-cy="Client-president"

                            value={client.president || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.president = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: jean mouloud de montalambere" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            TVA intercom.
                        </label>
                        <input
                            data-cy="Client-tvaIntracom"

                            value={client.tvaIntracom || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.tvaIntracom = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: FR0000000000" />
                    </div>


                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            contact
                        </label>
                        <input
                            data-cy="Client-contact"

                            value={client.contact || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.contact = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: moha begdadi" />
                    </div>


                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            contact phone
                        </label>
                        <input
                            data-cy="Client-contact-phone"

                            value={client.contactPhone || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.contactPhone = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: 0600000000" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            contact email
                        </label>
                        <input
                            data-cy="Client-contact-email"

                            value={client.contactMail || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.contactMail = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: user@exaple.com" />
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            address
                        </label>
                        <input
                            data-cy="Client-address"
                            value={client.address || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.address = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex:  12 rue de la reine Amelie" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            code postal
                        </label>
                        <input
                            data-cy="Client-zipCode"

                            value={client.zipCode || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.zipCode = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: 94000" />
                        <p className="text-gray-600 text-xs italic">Code postal du client</p>

                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            ville
                        </label>
                        <input
                            data-cy="Client-ville"

                            value={client.city || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...client };
                                em.city = e.target.value;
                                setClient(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: Bobiny" />
                        <p className="text-gray-600 text-xs italic">Ville ou le client se situe</p>
                    </div>

                </div>

            </form>
            <div className="pt-1 px-3 flex justify-between">
                <button
                    className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        navigate('/clients')
                    }}
                >
                    Annuler
                </button>
                <button
                    data-cy="SaveClient"

                    className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        submitForm();
                        console.log(client);
                    }}
                >
                    Valider
                </button>
            </div>
        </div>

    </div>
}
