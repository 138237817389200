import React, {useState} from 'react'
import {Tab} from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MissionTabs(props) {

    return (
        <>

            <Tab.Group>
                <div className={"flex flex-wrap -mx-3 mb-6 justify-center"}>

                    <Tab.List className="flex w-full max-w-md  space-x-1 rounded-xl bg-blue-50 p-1">
                        <Tab
                            className={({selected}) =>
                                classNames(
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 ',
                                    selected
                                        ? 'bg-white shadow'
                                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-blue-500'
                                )
                            }
                        >
                            {"Mission En cours"}
                        </Tab>
                        <Tab
                            className={({selected}) =>
                                classNames(
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 ',
                                    selected
                                        ? 'bg-white shadow'
                                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-blue-500'
                                )
                            }
                        >
                            {"Historique"}
                        </Tab>
                    </Tab.List>
                </div>
                <Tab.Panels className="mt-2">
                    <Tab.Panel
                        className={classNames(
                            'rounded-xl bg-white p-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}
                    >
                        {props.consultant.Missions.map((mission)=>{
                            return     <ul key={mission.id}>

                                <h3 className={"font-bold text-md uppercase text-gray-300 mb-3"}>Client:  {mission.Client.denominationSocial}</h3>
                                <h3 className={"font-bold text-md uppercase text-gray-300 mb-3"}>Mission:  {mission.title} - {mission.tjm} Euros</h3>
                                Missions En cours View Missions En cours View Missions En cours View Missions En cours View<br/>
                                Missions En cours View Missions En cours View Missions En cours View Missions En cours View<br/>
                                Missions En cours View Missions En cours View Missions En cours View<br/>
                            </ul>
                        })}


                    </Tab.Panel>

                    <Tab.Panel
                        className={classNames(
                            'rounded-xl bg-white p-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}
                    >
                        <ul className={'w-full'}>
                            coucou
                            <pre>
                              {JSON.stringify(props.consultant, null, 4)}
                            </pre>
                        </ul>
                    </Tab.Panel>

                </Tab.Panels>
            </Tab.Group>


        </>

    )
}
