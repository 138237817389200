import React, {useEffect} from "react";
import {Chart, registerables} from 'chart.js';
import * as Utils from "../ChartUtils";

Chart.register(...registerables);
const DATA_COUNT = 12;
const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

const labels = Utils.months({count: 12});
const data = {
    labels: labels,
    datasets: [
        {
            label: 'Compte rendu d\'activite',
            data: Utils.numbers(NUMBER_CFG),
            backgroundColor: Utils.CHART_COLORS.red,
        },
        {
            label: 'Consultants',
            data: Utils.numbers(NUMBER_CFG),
            backgroundColor: Utils.CHART_COLORS.blue,
        }
    ]
};

export function MainCraChart() {

    useEffect(() => {
        const ctx = document.getElementById('MainCraChart').getContext('2d');
       let mainCrachart  = new Chart(ctx, {
            type: 'bar',
            data,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Compte rendu d\'activite / Consultants'
                    },
                },
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        beginAtZero: true,
                        stacked: true
                    }
                }
            }
        });
        return () => {
            mainCrachart.destroy()
        }
    }, [])


    return <canvas id="MainCraChart" style={{width: '100%', maxHeight: '400px'}}/>;

}