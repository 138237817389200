import Service from "./Service";
import AuthManager from "../secu/inDbAuth";

class MeService extends Service {
    constructor() {
        super("/api/me");
    }


    uploadProfilePhoto(photo) {
        let token = localStorage.getItem("token")
       return  AuthManager.getConnectedUser().then(user=>{
            let formData = new FormData();
            formData.append("pic", photo);
            return fetch(this.url + "/pic", {
                method: "POST",
                body: formData,
                headers: {
                    "Authorization": "bearer " + token
                }
            })
                .then(response => {
                    if ( response.ok ) {
                        return response.json();
                    } else if ( response.status == 401 ) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                });
        })
    }

    getProfilePhoto() {
        let token = localStorage.getItem("token")
        return fetch(this.url + "/pic", {
            method: "GET",
            headers: {
                "Authorization": "bearer " + token
            }
        })
            .then(response => {
                if ( response.ok ) {
                    return response.blob();
                } else if ( response.status == 401 ) {
                    AuthManager.desAuthenticateUser();
                    window.location.href = '/login';
                }

            });
        // return  AuthManager.getConnectedUser().then(user=>{
        //     let token = user.token;
        //     //console.log(array)

       
        // })

    }
}

export default MeService;