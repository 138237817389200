import React from 'react'


export default function MissionGrid(props) {
    return (
        <div className="flex flex-col w-full">
            <div className="w-full">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="min-w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    {/* <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Titre
                                    </th> */}
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Client
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Consultant
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Employeur
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Debut/Fin
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        status
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {props.missions.map((person) => (
                                    <tr key={person.id}>
                                        {/* <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">

                                                <div className="">
                                                    <div
                                                        className="text-sm font-medium text-gray-900"> {person.title}</div>
                                                </div>
                                            </div>
                                        </td> */}
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">

                                                <div className="">
                                                    <div
                                                        className="text-sm font-medium text-gray-900"> {person.Client.denominationSocial}</div>

                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap  ">
                                            <div className="text-sm text-gray-900">{person.Consultant.Utilisateur.firstname} {person.Consultant.Utilisateur.lastname} </div>

                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap  ">
                                            <div className="text-sm text-gray-900">{person.Employeur.denominationSocial}</div>
                                            <div
                                                className="text-sm text-gray-500">{person.Client.zipCode} {person.Client.city}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap ">
                                            <div className="text-sm  text-gray-900">Du
                                                : {person.dateStart ? new Date(person.dateStart).toLocaleDateString("fr-FR") : "Aucune date de debut"}</div>
                                            <div
                                                className="text-sm  text-gray-900">au
                                                : {person.dateEnd ? new Date(person.dateEnd).toLocaleDateString("fr-FR") : "Aucune date de fin"}</div>
                                        </td>


                                        <td className="px-6 py-4 whitespace-nowrap w-1">
                                            <span
                                                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Active
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium w-1">
                                            <button
                                                className="bg-blue-500 hover:bg-blue-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (props.onDocument && typeof props.onDocument === "function") {
                                                        props.onDocument(person)
                                                    }
                                                }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                                                </svg>
                                            </button>
                                            <button
                                                className="bg-green-500 hover:bg-green-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (props.onEdit && typeof props.onEdit === "function") {
                                                        props.onEdit(person)
                                                    }
                                                }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                </svg>
                                            </button>
                                            <button
                                                className="bg-red-500 hover:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    if (props.onDelete && typeof props.onDelete === "function") {
                                                        props.onDelete(person)
                                                    }
                                                }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                                    viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                </svg>
                                            </button>
                                            {/* <button
                                            className="bg-blue-500 hover:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={e => {
                                                e.preventDefault();
                                                if (props.onMailSend && typeof props.onMailSend === "function") {
                                                    props.onMailSend(person)
                                                }
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                            </svg>
                                        </button> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

MissionGrid.defaultProps = {
    missions: [],
    label: "Button Text"
};