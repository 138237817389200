import React from 'react';
import AuthenticationContext from "./AuthenticationContext";
import {
    Navigate,
  } from "react-router-dom";
export default (BaseComponent, path) => {
    class RequireAuth extends React.Component {
        render() {
           return <AuthenticationContext.Consumer>
                {value =>{
                    if(value.isConnected()){
                    return (<BaseComponent {...this.props} />);
                    }else {
                        console.log(this.props)
                        //this.props.history.push(path || '/login');
                        return <Navigate to="/login" replace />;                    }
                }}
            </AuthenticationContext.Consumer>
        }
    }
    return RequireAuth;
};
