import React, { useContext, useEffect, useState } from "react";
import Profile from "../mini/Profile";
import Page from "./Page";
import AuthenticationContext from "../secu/AuthenticationContext";

export default function ProfilePage() {
    let authCtx = useContext(AuthenticationContext);
    let [user, setUser] = useState({
        Consultant:{}
    });

    useEffect(() => {
        // service.getProfilePhoto().then(blob => {
        //     var urlCreator = window.URL || window.webkitURL;
        //     var imageUrl = urlCreator.createObjectURL(blob);
        //     setSrc(imageUrl);
        // })
        setUser(authCtx.getAuthenticationData());


    }, [])
    return <Page>
        <Profile user={user}></Profile>
    </Page>;
}
