// eslint-disable-next-line no-unused-vars
import React from "react";
import {createRoot} from "react-dom/client";
// eslint-disable-next-line no-unused-vars
import App from "./App";
import "./styles.scss";


// let mountNode = mountNode || document.getElementById("app");
let root= createRoot(document.getElementById("app"))
root.render(
    // <React.StrictMode>
        <App/>
    // </React.StrictMode>
);

console.log(`
░█▄█░█░█░▀█▀░█▀█░▀█▀░█▀▄░█▀█░█▀█░█▀▀░▀█▀
░█░█░░█░░░█░░█░█░░█░░█▀▄░█▀█░█░█░█▀▀░░█░
░▀░▀░░▀░░▀▀▀░▀░▀░░▀░░▀░▀░▀░▀░▀░▀░▀▀▀░░▀░   
`);
// eslint-disable-next-line no-undef
if (module.hot) {
    // eslint-disable-next-line no-undef
    module.hot.accept()
}

