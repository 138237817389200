import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import AuthManager from "../secu/AuthManager";
import "./react-datepicker.scss";
import AdminsService from "../Services/AdminsService";


export default function AdminsForm(props) {
    // let openFileChoser = function () {
    //     var x = document.createElement("input");
    //     x.setAttribute("type", "file");
    //     x.accept = "image/*";
    //     document.body.appendChild(x);
    //     x.addEventListener('change', (e) => {
    //         //console.log(e);
    //         setPhoto(x.files[0]);
    //         //  let formData = new FormData();
    //         var urlCreator = window.URL || window.webkitURL;
    //         var imageUrl = urlCreator.createObjectURL(x.files[0]);
    //         setSrc(imageUrl);
    //         x.parentNode.removeChild(x);
    //     });
    //     x.click();
    //     //console.log(x);
    // }



    // let sendPhotoToServer = function () {
    //     let formData = new FormData();
    //     formData.append("profilePic", photo);
    //     fetch("/api/me/pic", {
    //         method: "POST",
    //         body: JSON.stringify(consultant),
    //         headers: {
    //             "content-type": 'application/json',
    //             "Authorization": "bearer " + AuthManager.getToken()
    //         }
    //     })
    //         .then(response => {
    //             if (response.ok) {
    //                 return response.json();
    //             } else if (response.status == 401) {
    //                 AuthManager.desAuthenticateUser();
    //                 window.location.href = '/login';
    //             }

    //         })
    //         .then(json => {
    //             navigate("/");
    //         })
    //         .catch(err => {
    //             console.error(err)
    //         });

    // }



    let submitForm = function () {
        if (props.match.params && props.match.params.id) {
            //je suis en modif
            service.update(consultant).then(json => {
                if (json.success) {
                    console.log(json)
                    navigate("/admins");
                }
            })
        } else {
            //je suis en ajout
            fetch("/api/admins", {
                method: "POST",
                body: JSON.stringify(consultant),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                })
                .then(json => {
                    if (json.success) {
                        navigate("/admins");
                    } else {
                        alert(json.message)
                    }
                })
                .catch(err => {
                    console.error(err)
                });
        }


    }
    const navigate = useNavigate();
    let [consultant, setConsultant] = useState({
        firstname:"HASSANI",
        login:"hassani.ayoub@gmail.com",
        lastname:"ayoub",

        Consultant: {
            Clients: [],
            Employeurs: []
        }
    });
    // const [startDate, setStartDate] = useState(new Date());
    // let [employeurID, setEmployeurID] = useState(0);
    // let [missionID, setMissionID] = useState(0);
    let service = new AdminsService();


    useEffect(() => {
        if (props.match.params && props.match.params.id) {
            service.getOne(props.match.params.id).then(json => {
                if (json.success) {
                    setConsultant(json.data[0]);
                    console.log(json.data[0]);
                }
            })
        }
    }, [])
    // let [clients, setClients] = useState([])
    // let [employeurs, setEmployeurs] = useState([])
    // let [src, setSrc] = useState("/img/profile.jpg");
    // let [photo, setPhoto] = useState(null);
    return <div className={"grid grid-cols-2 gap-4 divide-x divide-gray-200 border-b-2 border-gray-200 px-12"}>
        <div role="Form" className={"col-span-2"}>
            <form className="flex flex-col justify-center p-10">

                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Administrateur :</h3>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-first-name">
                            Nom
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name"
                            required={true}
                            value={consultant.firstname || ""}
                            onChange={e => {
                                let c = { ...consultant };
                                c.firstname = e.target.value;
                                setConsultant(c);
                            }}
                            type="text"
                            placeholder="ex: Dupont" />
                        <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            Prenom
                        </label>
                        <input
                            value={consultant.lastname || ""}
                            required={true}
                            onChange={e => {
                                let c = { ...consultant };
                                c.lastname = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: Romain" />
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3 md:w-1/2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-email">
                            email
                        </label>
                        <input
                            required={true}
                            value={consultant.login || ""}
                            onChange={e => {
                                let c = { ...consultant };
                                c.login = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-email" type="email" placeholder="ex: user@exaple.com" />
                        <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-city">
                            Telephone
                        </label>
                        <input
                            required={true}
                            value={consultant.phone || ""}
                            onChange={e => {
                                let c = { ...consultant };
                                c.phone = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-city"
                            type="number"
                            pattern={"[0-9]"}
                            step="0.01"
                            placeholder="0600000000" />
                        <p className="text-gray-600 text-xs italic">Tjm en €</p>

                    </div>
                </div>

                <div className="pt-5 flex justify-between">
                    <button
                        className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={e => {
                            navigate("/admins");
                        }}
                    >Annuler
                    </button>
                    <button
                        className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={e => {
                            submitForm();
                            //console.log(consultant);
                        }}
                    >
                        Valider
                    </button>
                </div>

            </form>


        </div>
    </div>
}