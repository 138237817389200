import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import AuthManager from "../secu/AuthManager";
import EmployeurService from "../Services/EmployeurService";


export default function EmployeurForm(props) {
    // let openFileChoser = function () {
    //     var x = document.createElement("input");
    //     x.setAttribute("type", "file");
    //     x.accept = "image/*";
    //     document.body.appendChild(x);
    //     x.addEventListener('change', (e) => {
    //         //console.log(e);
    //         setPhoto(x.files[0]);
    //         //  let formData = new FormData();
    //         var urlCreator = window.URL || window.webkitURL;
    //         var imageUrl = urlCreator.createObjectURL(x.files[0]);
    //         setSrc(imageUrl);
    //         // formData.append("profilePic", photo);
    //         // this.service.sendFile("/pic", formData).then(data => {
    //         //     var urlCreator = window.URL || window.webkitURL;
    //         //     var imageUrl = urlCreator.createObjectURL(data);
    //         //     this.pic.src = imageUrl;
    //         // });
    //         x.parentNode.removeChild(x);

    //     });
    //     x.click();

    //     //console.log(x);
    // }

    // let sendPhotoToServer = function () {
    //     let formData = new FormData();
    //     formData.append("profilePic", photo);
    //     fetch("/api/me/pic", {
    //         method: "POST",
    //         body: JSON.stringify(consultant),
    //         headers: {
    //             "content-type": 'application/json',
    //             "Authorization": "bearer " + AuthManager.getToken()
    //         }
    //     })
    //         .then(response => {
    //             if (response.ok) {
    //                 return response.json();
    //             } else if (response.status == 401) {
    //                 AuthManager.desAuthenticateUser();
    //                 window.location.href = '/login';
    //             }

    //         })
    //         .then(json => {
    //             navigate("/employeurs");
    //         })
    //         .catch(err => {
    //             console.error(err)
    //         });

    // }
    let service = new EmployeurService();
    let params = useParams();
    useEffect(() => {
        if (params && params.id) {
            service.getOne(params.id).then(json => {
                if (json.success) {
                    setEmployeur(json.data);
                }
            })
        }
    }, [])

    let submitForm = function () {
        if (params && params.id) {
            //je suis en modif
            service.update(employeur).then(json => {
                if (json.success) {
                    navigate("/employeurs");
                }
            })
        } else {
            fetch("/api/employeur", {
                method: "POST",
                body: JSON.stringify(employeur),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                })
                .then(json => {
                    navigate("/employeurs");
                })
                .catch(err => {
                    console.error(err)
                });
        }


    }
    const navigate = useNavigate();
    let [employeur, setEmployeur] = useState(
        {}
    );

    return <div className={"grid grid-cols-2 gap-4 divide-x divide-gray-200 border-b-2 border-gray-200"}>
        <div role="Form" className={"col-span-2"}>
            <form className="flex flex-col justify-center p-10">
                <div className="flex flex-wrap -mx-3 mb-6">

                    <div className="w-full  px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-denomination-sociale">
                            Denomination Social
                        </label>
                        <input
                            data-cy="denominationSocial"
                            value={employeur.denominationSocial || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.denominationSocial = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-denomination-sociale" type="text" placeholder="ex: EDF France" />
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            RCS
                        </label>
                        <input
                            data-cy="rcs"
                            value={employeur.rcs || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.rcs = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: Ville 000 000 000" />
                    </div>


                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            Siret
                        </label>
                        <input
                            data-cy="siret"
                            value={employeur.siret || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.siret = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: 84074430400022" />
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            President
                        </label>
                        <input
                            data-cy="president"

                            value={employeur.president || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.president = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: jean mouloud de montalambere" />
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            TVA intercom.
                        </label>
                        <input
                            data-cy="tvaIntracom"

                            value={employeur.tvaIntracom || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.tvaIntracom = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: FR0000000000" />
                    </div>


                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            contact
                        </label>
                        <input
                            data-cy="contact"

                            value={employeur.contact || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.contact = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: moha begdadi" />
                    </div>


                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            contact phone
                        </label>
                        <input
                            data-cy="contactPhone"
                            value={employeur.contactPhone || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.contactPhone = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: 0600000000" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            contact email
                        </label>
                        <input
                            data-cy="contactMail"

                            value={employeur.contactMail || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.contactMail = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex:  user@exaple.com" />
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            address
                        </label>
                        <input
                            data-cy="address"
                            value={employeur.address || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.address = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: 12 rue de la reine Amelie" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            code postal
                        </label>
                        <input
                            data-cy="zipCode"

                            value={employeur.zipCode || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.zipCode = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: 94000" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name">
                            ville
                        </label>
                        <input
                            data-cy="city"
                            value={employeur.city || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...employeur };
                                em.city = e.target.value;
                                setEmployeur(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: Bobiny" />
                        <p className="text-gray-600 text-xs italic">Ville ou l'ESN se situe</p>
                    </div>

                </div>

            </form>
            <div className="pt-1 px-3 flex justify-between">
                <button
                    className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        navigate('/employeurs')
                    }}
                >
                    Annuler
                </button>
                <button
                    data-cy="SaveEmployeur"

                    className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        submitForm();
                        console.log(employeur);
                    }}
                >
                    Valider
                </button>
            </div>
        </div>

    </div>
}
