import React from 'react';
//import Commun from './Commun';
import V from '@ahassani/rex';
V["password"] = /^(?=.*[A-Z])(?=.*[\-!#$@%&'*+\/=?^_`{|}~])(?=.*[0-9])(?=.*[a-z]).{8,}$/

class Field extends React.Component {
    constructor() {
        super();
        this.id = this.uuidv4();
        //this.label = React.createRef();
        this.state = {
            labelClassName: '',
            focusCount: 0,
            errmsg: ""
        };
    }

    getDefaults() {
        return {
            placeholder: '',
            id: Date.now(),
            className: 'validate',
            label: 'Text',
            type: 'text',
            icon: 'account_circle'
        };
    }

    getValue() {
        return this.el.value;
    }

    setValue(val) {
        this.el.current.value = val;
        if (val !== "") {
            this.label.classList.add('active');
        }
    }

    isValid() {
        return this.validate(true);
    }

    onBlur() {
        if ((this.props.placeholder !== '') || (this.el && this.el.value !== "")) {
            if (!this.label.classList.contains("active")) {
                this.label.classList.add('active');
            }
        } else {
            this.label.classList.remove('active');
        }
        if (typeof this.props.onBlur === "function") {
            this.props.onBlur();
        }
        this.validate(true);
        this.setState({ focusCount: this.state.focusCount + 1 });
    }

    onFocus() {
        if (!this.label.classList.contains("active")) {
            this.label.classList.add('active');
        }
        if (typeof this.props.onFocus === "function") {
            this.props.onFocus();
        }
    }


    validate(blure = false) {
        if (blure) {
            this.setState({ focusCount: this.state.focusCount + 1 });
        }
        if (this.state.focusCount > 0 || blure) {
            const { min, max, required, vtype, vtypeMsg } = this.props;
            if (this.el.value !== '') {
                let length = this.el.value.length;
                if (min && max && (length < min || length > max)) {
                    this.setState({ errmsg: `doit avoir une longeur comprise entre ${min} et ${max}.` });
                    // this.el.classList.remove('valid');
                    // this.el.classList.add('invalid');
                    this.setInvalid();
                    return false;
                } else if (!min && max && length > max) {
                    this.setState({ errmsg: `doit avoir une longeur maximal de ${max}.` });
                    // this.el.classList.remove('valid');
                    // this.el.classList.add('invalid');
                    this.setInvalid();
                    return false;
                } else if (min && !max && length < min) {
                    this.setState({ errmsg: `doit avoir une longeur minimal de ${min}.` });
                    // this.el.classList.remove('valid');
                    // this.el.classList.add('invalid');
                    this.setInvalid();
                    return false;
                } else if (vtype && vtype == "password" && !V[vtype].test(this.el.value)) {
                    this.setState({ errmsg: vtypeMsg || `Le mot de passe doit contenir une maj, une min, un car special,et numerique, minimum 8 charracteres au total.` });
                    // this.el.classList.remove('valid');
                    // this.el.classList.add('invalid');
                    this.setInvalid();
                    return false;
                } else if (vtype && !V[vtype].test(this.el.value)) {
                    this.setState({ errmsg: vtypeMsg || `Doit être un ${vtype} valide.` });
                    // this.el.classList.remove('valid');
                    // this.el.classList.add('invalid');
                    this.setInvalid();
                    return false;
                } else {
                    this.setState({ errmsg: `` });
                    // this.el.classList.remove('invalid');
                    // this.el.classList.add('valid');
                    this.setValid()
                    return true;
                }

            } else {
                if (required) {
                    this.setState({ errmsg: `Ne peu pas etre vide.` });
                    this.setInvalid();
                    return false;
                } else {
                    this.setState({ errmsg: `` });
                    this.setValid()
                    return true;
                }
            }

        }

    }
    setCustomError(errmsg) {
        this.setState({ errmsg});

    }
    setInvalid() {
        this.el.classList.remove('border-green-500');
        this.el.classList.add('border-red-500');
        //      this.el.focus();
    }
    setValid() {
        this.el.classList.remove('border-red-500');
        this.el.classList.add('border-green-500');
        //      this.el.focus();
    }
    componentDidMount() {
        if ((this.props.placeholder !== '') || (this.el && this.el.value !== "")) {
            this.label.classList.add('active');
            this.setState({
                labelClassName: 'active'
            });
        }

    }

    onKeyUp(e) {

        if (e.key === "Enter" && this.props.enteraction) {
            this.props.enteraction(e, this);
        }
        this.validate();
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    getErrorView() {
        if (this.state.errmsg != null && this.state.errmsg !== "") {
            return <p className="text-gray-600 text-xs italic">{this.state.errmsg}</p>;
        }
    }

    render() {
        // border-green-500 
        return <div className="input-field">
            <label ref={ref => (this.label = ref)}
                htmlFor={this.id}
                className={this.state.labelClassName}>{this.props.label}</label>
            <input
                ref={ref => {
                    this.el = ref;
                }}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type={this.props.type || "text"}
                placeholder={this.props.placeholder}
                onFocus={(e) => this.onFocus.bind(this)(e)}
                onBlur={(e) => this.onBlur.bind(this)(e)}
                onKeyUp={this.onKeyUp.bind(this)}

                value={this.props.value || ''}
                onChange={this.props.onChange || (() => {
                })}
                data-cy={this.props['data-cy']}
                id={this.id}
                autoFocus={this.props.disabled || false}
                disabled={this.props.disabled || false}
            />
            {this.getErrorView()}
        </div>;
    }
}

Field.defaultProps = {
    type: 'text',
    className: '',
    placeholder: '',
    label: ''
};
export default Field;
