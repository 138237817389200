import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Page from "./Page";
import ClientService from "../Services/ClientService";
import ClientGrid from "../mini/ClientGrid";


export default function Clients() {
    const navigate = useNavigate();
    let [clients, setClients] = useState([]);
    let service = new ClientService();
    useEffect(() => {
        service.getAll().then(json => {
            if ( json.success ) {
                setClients(json.data);
            }
        })
    }, [])
    let deleteClient = (client) => {
        if(confirm("Etes vous sur de vouloir supprimer "+client.denominationSocial+" ?")) {

            service.delete(client.id).then(json => {
                if (json.success) {
                    let e = clients
                    e = e.filter((em) => {
                        return em.id != client.id
                    })
                    setClients(e)
                }
            })
        }
    }
    return <Page>
        <div className="pt-2 sm:px-6 lg:px-8">
            <button
                data-cy={"New-Client-Btn"}
                className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={e => {
                    navigate('/clients/create')
                }}> Nouveau client </button>
        </div>
        <ClientGrid
            onEdit={e=>{
                navigate('/clients/create/'+e.id)
            }}
            onDelete={deleteClient}
            clients={clients}></ClientGrid>
    </Page>;
}
