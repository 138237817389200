import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Field from "../forms/mini/Field";
// import AuthenticationContext from "../secu/AuthenticationContext";
import SecuredLinkService from "../Services/SecuredLinkService";

export default function SecuredResetPasswordPage(props) {
    // const authContext = useContext(AuthenticationContext);
    let params= useParams();
    const navigate = useNavigate();
    let [user, setUser] = useState({});
    let [cpassword, setCpassword] = useState("");
    let [password, setPassword] = useState("");
    let [errorMessage, setErrorMessage] = useState(null);
    let [message, setMessage] = useState(null);
    let [reset, setReset] = useState(false);
    const cpasswordF = useRef(null);
    const passwordF = useRef(null);
    let getErrorMessageView = () => {
        if (errorMessage && errorMessage != "" && errorMessage != null) {
            return <div className="bg-red-200 rounded px-3 py-3 text-center mb-3 font-bold">
                <small>{errorMessage}</small>
            </div>;
        }

    };
    let getMessageView = () => {
        if (message && message != "" && message != null) {
            return <div className="bg-lightBlue-200 rounded px-3 py-3 text-center mb-3 font-bold">
                <small>{message}</small>
            </div>;
        }
    };
    let login = () => {
        // if (cpasswordF.current.checkValidity() && passwordF.current.checkValidity()) {
        //    if (password != cpassword) {
        //         setMessage(null)
        //         setErrorMessage("Le mot de passe et sa confirmation ne sont pas identiques")
        //     } else {
                setErrorMessage(null)
                setMessage("Enregistrement du nouveau mot de passe ....")
                service.resetPassword(password).then(json => {
                    if(json.success){
                        setReset(true);
                        setTimeout(()=>{
                            navigate('/login');
                        },8000)
                    }else{
                        setMessage(null)
                        setErrorMessage(json.message)
                    }
                })


        //     }
        // } else {
        //     setMessage(null)
        //     setErrorMessage("les infos saisies ne sont pas coherantes")
        // }
    };
    let service = new SecuredLinkService(params.token)

    useEffect(() => {
        service.getInfos().then(json => {
            setUser(json.data);
        })
    }, [])
    if (reset) {
        return <>
            <div className="container mx-auto px-4 h-full flex item-center flex-col mt-20">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-6/12 px-4">
                        <div
                            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0 p-10">
                            Le mot de passe de : {user.login} a ete modifie
                            <div className="flex flex-wrap mt-6 relative">
                                <div className="w-full text-right">
                                    <Link
                                    data-cy={'connectLink'}
                                        to="/login"
                                        className="text-blue-600"
                                    >
                                        <small>Se connecter </small>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>;
    }
    return (<>
        <div className="container mx-auto px-4 h-full flex item-center flex-col mt-20">
            <div className="flex content-center items-center justify-center h-full">
                <div className="w-full lg:w-6/12 px-4">
                    <div className={"mb-3 text-center"}>Modification de mot de pass de : {user.login}</div>

                    <div
                        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">

                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-10">
                            {getErrorMessageView()}
                            {getMessageView()}

                            <form>
                                {/* <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Veillez saisir votre Email :
                                        </label>
                                        <input
                                            type="email"
                                            required={true}
                                            ref={emailF}
                                            value={email}
                                            onChange={e => {
                                                setEmail(e.target.value);
                                            }}
                                            className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Email"
                                        />
                                    </div> */}

                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Password
                                    </label>
                                    <Field
                                        data-cy={"pass"}
                                        type="password"
                                        vtype="password"
                                        value={password}
                                        ref={passwordF}
                                        onChange={e => {
                                            setPassword(e.target.value);
                                        }}
                                        required={true}
                                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Password"
                                    />
                                </div>
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Confirmation de Password
                                    </label>
                                    <Field
                                        data-cy={"cpass"}
                                        type="password"
                                        vtype="password"
                                        value={cpassword}
                                        ref={cpasswordF}
                                        onChange={e => {
                                            setCpassword(e.target.value);
                                        }}
                                        required={true}
                                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Password"
                                    />
                                </div>

                                <div className="text-center mt-6">
                                    <button
                                        className="bg-gray-300 active:bg-gray-600 text-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={login}
                                        data-cy={"passwordBtn"}

                                    >
                                    Valider                                    
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}
