import React, {useContext} from "react";
import NavBar from "../mini/NavBar";
import AuthenticationContext from "../secu/AuthenticationContext";
import AdminNavBar from "../mini/AdminNavBar";
export default function Page(props) {
    let ctx = useContext(AuthenticationContext)
    let getNavBar = ()=>{
        if(ctx.isSuperAdmin()||ctx.isAdmin()){
            return <AdminNavBar />
        }
        return <NavBar onLogedOut={e => {}}></NavBar>;
    }
    return <div className="formFull">
        {getNavBar()}
        {props.children}
    </div>;
}
