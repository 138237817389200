/*
import React from "react";
import { hot } from 'react-hot-loader/root';
import Example from "./cmps/mini/test";

class App extends React.Component {
  render() {
    const { name } = this.props;
    return (
      <>
        <h1 className="text-4xl text-white bg-black">My intranet</h1>
            <Example></Example>
      </>
    );
  }
}

export default hot(App);*/


import React from "react";
import { Route, Routes ,BrowserRouter} from "react-router-dom";
import routes from "./routes";


function App() {

    return (
        <BrowserRouter>
            <div className={"h-full"}>
                <Routes>
                    {routes.map((route, i) => {
                        return <Route path={route.path} element={<route.component/>} key={i}></Route>
                    })}
                </Routes>
            </div>
        </BrowserRouter>

    );
}

export default App;
