
import React, {useEffect} from "react";
import { Chart, registerables} from 'chart.js';
import * as Utils from '../ChartUtils.js';

Chart.register(...registerables);
const DATA_COUNT = 2;
const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

const data = {
    labels: ['Uploaded', 'En retard'],
    datasets: [
        {
            label: 'Dataset 1',
            data: Utils.numbers(NUMBER_CFG),
            backgroundColor: Object.values(Utils.CHART_COLORS),
        }
    ]
};
export function CraChart() {

    useEffect(() => {
        const ctx = document.getElementById('CraChart').getContext('2d');
        let crachart = new Chart(ctx, {
            type: 'pie',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio:true,
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: 'CRAs En retard'
                    }
                }
            },
        });
        return () => {
            crachart.destroy()
        }
    },[])


    return <canvas id="CraChart" style={{width: '100%', maxHeight: '400px'}} className="w-50"/>;

}