import React from 'react';
import {
    login,
    logout,
    isConnected,
    isSuperAdmin,
    isAdmin,
    isconsultant,
    getAuthenticationData,
    updateUser,
    updatePassword,
    sendResetPasswordMail
} from "./AuthenticationHelper";

const AuthenticationContext = React.createContext(
    {
        displayName: "MyIntra-auth",
        login,
        logout,
        isSuperAdmin,
        isAdmin,
        isconsultant,
        isConnected,
        getAuthenticationData,
        updateUser,
        updatePassword,
        sendResetPasswordMail
    }
);
export default AuthenticationContext;