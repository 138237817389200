import React from "react";
import {Link} from "react-router-dom";


// let options = [];
// options.push({
//     id: 'none',
//     name: "Nombre de jours ?",
// })
// for (let i = 0; i <= 230; i+=5) {
//     options.push({
//         id: i,
//         name: i/10,
//     })
// }


// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }

export default function Perime(props) {
    // let SecuKey = props.location.pathname.split("/")[2]
    return <div>
        <div style={{display: "flex", justifyContent: "center"}}>
            <img src="/img/milogo.png"></img>

        </div>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "400px"}}>
            <div className={"text-center"}>
                <p> Vous ne pouvez plus utiliser ce liens, Ce liens est périmé</p>
                <br/> <br/>
                <p>Veuillez vous connecter a votre profile, pour saisir Un nouveau prévisionnel ou charger un compte
                    rendu d'activités<br/> <br/><Link
                    className={"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"}
                    to={"/login"}> Je me connect à mon profile</Link></p>

            </div>
        </div>
    </div>
}
