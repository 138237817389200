import Dexie from 'dexie';

const DBNAME = 'MyIntranet'
const VERSION = 2

const db = new Dexie(DBNAME);
db.version(VERSION).stores({ Consultant: "++id,login", Authenticated: "++id" });

class InDbAuth {
    static Hooks = {
        login: {},
        logout: {}
    };
    static DBNAME = 'MyIntranet'
    static VERSION = 2

    /**
     * Authenticate a user. Save a token string in Local Storage
     * @param {string} token
     */
    static authenticateUser(User) {
        this.setAuthenticationData(User);
       // document.cookie = "Bearer=" + User.token;
        Object.keys(InDbAuth.Hooks.login).forEach(key => {
            let fn = InDbAuth.Hooks.login[key];
            fn(User);
        });
    }

    static setAuthenticationData(User) {
        db.Authenticated.add(User);
        /*
                let request = indexedDB.open(InDbAuth.DBNAME, InDbAuth.VERSION);
                request.onerror = function (event) {
                    alert("Pourquoi ne permettez-vous pas à ma web app d'utiliser IndexedDB?!");
                };
                request.onupgradeneeded = function (event) {
                    let db = event.target.result;

                    // Crée un objet de stockage pour cette base de données
                    let objectStore = db.createObjectStore("Authenticated", { keyPath: "id" });
                    objectStore.transaction.oncomplete = function (event) {
                        // Stocker les valeurs dans le nouvel objet de stockage.
                        let AuthenticatedStore = db.transaction("Authenticated", "readwrite").objectStore("Authenticated");
                        AuthenticatedStore.clear();
                        AuthenticatedStore.add({ ...User });
                    }
                };*/


        //localStorage.setItem("userData", JSON.stringify(User));
        //localStorage.setItem("access_token", User.token);
    }

    static setAuthenticationEmail(email) {
        let u = this.getAuthenticationData();
        u.login = email;
        this.setAuthenticationData(u);
    }

    static getAuthenticationData(success) {
        db.Authenticated.toArray().then(array => {
            success(array[0])
        })
    }

    /**
     * Check if a user is authenticated - check if a token is saved in Local Storage
     *
     * @returns {boolean}
     */
    static isUserAuthenticated(cb) {
        db.Authenticated.count().then(c => {
            if ( c == 1 ) {
                cb(true)
            } else {
                cb(false)
                this.desAuthenticateUser();
            }
        });
    }

    /**
     * Deauthenticate a user. Remove a token from Local Storage.
     *
     */
    static desAuthenticateUser() {
        db.Authenticated.clear();
       // document.cookie = "Bearer=undefined";

    }

    /**
     * Get a token value.
     *
     * @returns {string}
     */

    static getToken(success) {
       return db.Authenticated.toCollection().first();
    }

    static getConnectedUser() {
        return db.Authenticated.toCollection().first();
    }
}

export default InDbAuth;
