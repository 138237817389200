import React, {useContext } from "react";


import Page from "./Page";
import AdminDash from "../mini/AdminDash";
import AuthenticationContext from "../secu/AuthenticationContext";
import ConsultantsDash from "../mini/ConsultantsDash";

export default function DashBoard() {
    let AuthContext = useContext(AuthenticationContext);
    return <Page>

        {(AuthContext.isAdmin() || AuthContext.isSuperAdmin()) && <AdminDash></AdminDash>}
        {AuthContext.isconsultant() && <ConsultantsDash></ConsultantsDash>}

    </Page>;
}
