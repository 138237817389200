import React, {useEffect} from "react";
import {Chart, registerables} from 'chart.js';
import * as Utils from "../ChartUtils";

Chart.register(...registerables);
const DATA_COUNT = 12;
const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

const labels = Utils.months({count: 12});
const data = {
    labels: labels,
    datasets: [
        {
            label: 'CA des 12 derniers mois',
            data: Utils.numbers(NUMBER_CFG),
            backgroundColor: "#0865FA",
        }
    ]
};

export function CAChart() {

    useEffect(() => {
        let ctx = document.getElementById('CAChart').getContext('2d');
         let cachart = new Chart(ctx, {
            type: 'bar',
            data,
            options: {
                plugins: {
                    title: {
                        display: false,
                        text: 'Previsioonels / Consultants'
                    },
                },
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        beginAtZero: true,
                        stacked: true
                    }
                }
            }
        });
        return () => {
            cachart.destroy()
        }

    }, [])


    return <div style={{width: '100%', height: '400px'}}><canvas style={{width: '100%', maxHeight: '400px'}} id="CAChart" /></div>;

}