import React from "react";
import Field from "../forms/mini/Field";
import SettingsService from "../Services/SettingsService";
import Page from "./Page";
import Swal from 'sweetalert2'
import {Switch} from "@headlessui/react"

class Settings extends React.Component {
    state = {
        heurPrev: "",
        minutePrev: "",
        datePrev: "",
        heurCra: "",
        minuteCra: "",
        dateCra: "",
        sendMail: true,
        sendSms: true
    }

    constructor(props) {
        super(props);
        this.service = new SettingsService();

    }

    componentDidMount() {
        this.service.getAll().then(json => {
            console.log(json.data)

            if (json.success) {
                console.log(json.data)
                this.setState(json.data)
            }
        })
    }


    saveValuesToserver() {
        this.service.insert(this.state).then(json => {
            if (json.success) {
                console.log(json.data)
                Swal.fire({
                    icon: 'success',
                    title: 'Operation reussi',
                    text: 'La modification a bien ete prise en compte',
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: json.mesage
                })
            }
        })
    }

    render() {
        return <Page>
            <div className="container mx-auto mt-4 ">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-5 shadow-xl rounded-lg ">
                    <div className="p-6">
                        <h3 className={"font-bold text-lg uppercase text-gray-400 mt-3 mb-6 px-3"}>Configuration des
                            envois des Notifications de Previsionnels: </h3>
                        <div className="flex flex-wrap mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                       htmlFor="grid-first-name">
                                    Jours du mois
                                </label>
                                <Field
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="grid-first-name"
                                    required={true}
                                    vtype={"num"}
                                    step={.5}
                                    value={this.state.datePrev}
                                    onChange={e => {
                                        this.setState({datePrev: e.target.value})
                                    }}
                                    type="number"
                                    placeholder="ex: 20"/>
                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                            </div>
                            <div className="md:w-1/4 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                       htmlFor="grid-last-name">
                                    Heure
                                </label>
                                <Field
                                    vtype={"num"}
                                    required={true}
                                    value={this.state.heurPrev}
                                    onChange={e => {
                                        this.setState({heurPrev: e.target.value})
                                    }}
                                    step={.5}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name" type="number" placeholder="ex: 10"/>
                            </div>
                            <div className="md:w-1/4 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                       htmlFor="grid-last-name">
                                    Minutes
                                </label>
                                <Field
                                    vtype={"num"}
                                    required={true}
                                    value={this.state.minutePrev}
                                    onChange={e => {
                                        this.setState({minutePrev: e.target.value})
                                    }}
                                    step={.5}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name" type="number" placeholder="ex: 25"/>
                            </div>
                        </div>
                        <h3 className={"font-bold text-lg uppercase text-gray-400 mt-9 mb-6 px-3"}>Configuration des
                            envois des Notifications de Comptes rendu d'activitees: </h3>
                        <div className="flex flex-wrap  mb-6">
                            <div className="md:w-1/2 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                       htmlFor="grid-last-name">
                                    Jours du mois
                                </label>
                                <Field
                                    vtype={"num"}
                                    required={true}
                                    value={this.state.dateCra}
                                    onChange={e => {
                                        this.setState({dateCra: e.target.value})
                                    }}
                                    step={.5}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name" type="number" placeholder="ex: 25"/>
                            </div>
                            <div className="md:w-1/4 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                       htmlFor="grid-last-name">
                                    Heure
                                </label>
                                <Field
                                    vtype={"num"}
                                    required={true}
                                    value={this.state.heurCra}
                                    onChange={e => {
                                        this.setState({heurCra: e.target.value})
                                    }}
                                    step={.5}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name" type="number" placeholder="ex: 10"/>
                            </div>
                            <div className="md:w-1/4 px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                       htmlFor="grid-last-name">
                                    Minutes
                                </label>
                                <Field
                                    vtype={"num"}
                                    required={true}
                                    value={this.state.minuteCra}
                                    onChange={e => {
                                        this.setState({minuteCra: e.target.value})
                                    }}
                                    step={.5}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name" type="number" placeholder="ex: 25"/>
                            </div>
                        </div>




                        <div className="flex flex-wrap mt-12">
                            <div className="flex justify-center w-full px-3">
                                <Switch.Group >
                                    <Switch.Label className="mr-4">activer les envoie des Emails</Switch.Label>
                                    <Switch
                                        id={"SendMailSwitch"}
                                        checked={this.state.sendMail}
                                        onChange={(e) => {
                                            this.setState({sendMail: e})
                                        }
                                        }
                                        className={`${
                                            this.state.sendMail ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                    >
                                        <span className="sr-only">Enable notifications</span>
                                        <span
                                            className={`${
                                                this.state.sendMail ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white`}
                                        />
                                    </Switch>


                                    <Switch.Label className="mr-4 ml-8">activer les envoie d'sms</Switch.Label>
                                    <Switch
                                        id={"SendMailSwitch"}
                                        checked={this.state.sendSms}
                                        onChange={(e) => {
                                            this.setState({sendSms: e})
                                        }}
                                        className={`${
                                            this.state.sendSms ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                    >
                                        <span className="sr-only">Enable notifications</span>
                                        <span
                                            className={`${
                                                this.state.sendSms ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white`}
                                        />
                                    </Switch>
                                </Switch.Group>
                            </div>
                        </div>
                    </div>


                    <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                        <div className="flex flex-wrap justify-center">
                            <div className="w-full  px-4 text-right">
                                <button
                                    className="bg-green-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={this.saveValuesToserver.bind(this)}
                                >
                                    Enregistrer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </Page>

    }
}

export default Settings;