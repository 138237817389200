import React, { useEffect, useState } from "react";
import SecuredLinkService from "../Services/SecuredLinkService";

export default function CompleteProfile(props) {
    let [ password, setPassword ] = useState("")
    let [ user, setUser ] = useState({})

    let [ PasswordConfirmation, setPasswordConfirmation ] = useState("")
    let SecuKey = props.location.pathname.split("/")[2]
    let service = new SecuredLinkService(SecuKey)
    useEffect(function(){
        service.getInfos().then(json=>{
            setUser(json.data);
        })
    },[])


    let saveInfos=()=>{
        user.password = password;
        service.saveInfos(user).then(json=>{
            console.log(json);
        })
    }


    return <>
        <div className="container mx-auto px-4 w-50">

            <div  className="text-xl font-medium text-black text-center mt-5 mb-5">
                <h4> {user.firstname} {user.lastname}</h4>
                Veuillez completer vos informations</div>
            <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type={"text"}
                placeholder={"Address"}
            />
            <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type={"text"}
                placeholder={"Telephone"}

            />
            <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type={"password"}
                placeholder={"nouveau mot de pass"}
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-blue-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type={"password"}
                placeholder={"confirmation de mot de pass"}
                value={PasswordConfirmation}
                onChange={e => setPasswordConfirmation(e.target.value)}
            />

            <div className="pt-6 text-right">
                <button
                    className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={saveInfos}
                >
                    Valider mes informations
                </button>
            </div>


        </div>

    </>;
}
