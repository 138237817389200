import React, {useEffect} from 'react';
import {PrevChart} from "./Charts/PrevChart";
import {CraChart} from "./Charts/CraChart";
import {MainBarsChart} from "./Charts/MainBarsChart";
import {MainCraChart} from "./Charts/MainCraChart";

export default function AdminDash() {

    useEffect(() => {

    }, [])

    return <>
        <div className="grid grid-cols-2">

            <div>
                <PrevChart/>
            </div>
            <div>
                <CraChart/>
            </div>

        </div>
        <MainBarsChart/>
        <MainCraChart/>
    </>;
}
