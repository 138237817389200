import React, {useState, useRef, useContext} from "react";
import {Link} from "react-router-dom";
import AuthenticationContext from "../secu/AuthenticationContext";

export default function ResetPasswordPage() {
    const authContext = useContext(AuthenticationContext);
    // let history = useHistory();
    let [email, setEmail] = useState("0619629393");
    let [errorMessage, setErrorMessage] = useState(null);
    let [message, setMessage] = useState(null);
    let [emailSent, setEmailSent] = useState(false);
    const emailF = useRef(null);
    // const passwordF = useRef(null);
    let getErrorMessageView = () => {
        if (errorMessage && errorMessage != "" && errorMessage != null) {
            return <div className="bg-red-200 rounded px-3 py-3 text-center mb-3 font-bold">
                <small>{errorMessage}</small>
            </div>;
        }

    };
    let getMessageView = () => {
        if (message && message != "" && message != null) {
            return <div className="bg-lightBlue-200 rounded px-3 py-3 text-center mb-3 font-bold">
                <small>{message}</small>
            </div>;
        }
    };
    let resetEmail = () => {
        setMessage("Reset password in progress ....")
        // if (emailF.current.checkValidity()) {
            authContext.sendResetPasswordMail(email).then(user => {
                console.log(user);
                //history.push('/');
                setMessage(null)
                setEmailSent(true)
            }).catch(err => {
                setMessage(null)
                setErrorMessage(err.message)
            })
        // } else {
        //     alert("les infos saisies ne sont pas coherantes");
        // }
    };
    if (emailSent) {
        return <>
            <div className="container mx-auto px-4 h-full flex item-center flex-col mt-20">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-6/12 px-4">
                        <div
                            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0 p-10">
                            Un email a ete envoye a {email}
                            <div className="flex flex-wrap mt-6 relative">
                                <div className="w-full text-right">
                                    <Link
                                        to="/login"
                                        className="text-blue-600"
                                    >
                                        <small>Se connecter </small>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    }
    return (<>
            <div className="container mx-auto px-4 h-full flex item-center flex-col mt-20">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-6/12 px-4">
                        <div
                            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">

                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-10">
                                {getErrorMessageView()}
                                {getMessageView()}
                                <form>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Veillez saisir votre Mail ou Numéro de téléphone :
                                        </label>
                                        <input
                                            type="email"
                                            required={true}
                                            ref={emailF}
                                            value={email}
                                            onChange={e => {
                                                setEmail(e.target.value);
                                            }}
                                            className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Email"
                                        />
                                    </div>
                                    {/* <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Ou votre Numerode telephone :
                                        </label>
                                        <input
                                            type="number"
                                            // required={true}
                                            // ref={emailF}
                                            // value={email}
                                            // onChange={e => {
                                            //     setEmail(e.target.value);
                                            // }}
                                            className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="0600000000"
                                        />
                                    </div> */}
                                    <div className="text-center mt-6">
                                        <button
                                            className="bg-gray-300 active:bg-gray-600 text-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={resetEmail}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </form>
                                <div className="flex flex-wrap mt-6 relative">
                                    <div className="w-full text-right">
                                        <Link
                                            to="/login"
                                            className="text-blue-600"
                                        >
                                            <small>Se connecter </small>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
