import Service from './Service';
import {default as AuthManager} from "../secu/inDbAuth";
class SecuredLinkService extends Service{
    constructor(key) {
        super('/api/securedLink/'+key);
        this.key = key;
    }

    getInfos(){
        return new Promise((resolve, reject) => {
            fetch(this.url + '/infos' , {
                method: "GET",
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                }).then(jsonresponse => { resolve(jsonresponse); })
                .catch(err => { reject(err); });
        });
    }


    resetPassword(password){
        return new Promise((resolve, reject) => {
            fetch( '/api/reset/'+this.key, {
                method: "POST",
                body: JSON.stringify({password}),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                }).then(jsonresponse => { resolve(jsonresponse); })
                .catch(err => { reject(err); });
        });
    }
    saveInfos(data){
        return new Promise((resolve, reject) => {
            fetch(this.url + '/saveInfos', {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                }).then(jsonresponse => { resolve(jsonresponse); })
                .catch(err => { reject(err); });
        });
    }

}
export default SecuredLinkService;