/**
 * Log the user in
 * @param {string} email the email address that we need to authenticate
 * @param {string} password The password associated to the email
 */
export function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email, password})
    };
    return fetch(`/api/login`, requestOptions)
        .then(handleJsonResponse)
        .then(json => {
            if (json.success) {
                let user = json.data
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', user.token);
                delete user.token;
                localStorage.setItem('UserData', JSON.stringify(user));
                return user;
            }else{
                throw new Error(json.message)
            }
        });
}
export function sendResetPasswordMail(email) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email})
    };
    return fetch(`/api/sendreset`, requestOptions)
        .then(handleJsonResponse)
        .then(json => {
            if (json.success) {
                let user = json.data
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', user.token);
                delete user.token;
                localStorage.setItem('UserData', JSON.stringify(user));
                return user;
            }else{
                throw new Error(json.message)
            }
        });
}


function handleJsonResponse(response) {
    return response.json().then(json => {
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                logout();
                location.reload(true);
            }
            const error = (json && json.message) || response.statusText;
            return Promise.reject(error);
        }
        return json;
    });
}

export function updateUser(user) {
    delete user.password;
}

export function updatePassword(password) {

}

export function isSuperAdmin() {
    let user = getAuthenticationData();
    return user && user.type && user.type === "SuperAdmin"
}

export function isAdmin() {
    let user = getAuthenticationData();
    return user && user.type && user.type === "Admin"
}

export function isconsultant() {
    let user = getAuthenticationData();
    return user && user.type && user.type == "Consultant"
}

export function isConnected() {
    try {
        return localStorage.getItem("token") !== null && localStorage.getItem("token") !== undefined;
    } catch (error) {
        this.desAuthenticateUser();
    }
}

export function getAuthenticationData() {
    let dataStr = localStorage.getItem('UserData');
    return JSON.parse(dataStr)

}

export function logout() {
    localStorage.removeItem("token")
    localStorage.removeItem("UserData")
}
