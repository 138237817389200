/* This example requires Tailwind CSS v2.0+ */
import React, {  useState } from 'react'
import Modal from "../mini/Modal";
import ClientService from '../Services/ClientService';


export default function ModalClientForm(props) {
    // let setModalOpen;
    const [client, setClient] = useState({})
    // const cancelButtonRef = useRef(null)
    let service = new ClientService();
    return (
        <Modal title={'Creation de nouveau client'}
            onValidate={(e, closeFn, email) => {
                service.insert(client).then(res => {
                    if (res.success) {
                        props.aftersave && props.aftersave(res.data);
                        closeFn(false)
                    }
                });
            }}
            getTools={({ setOpen }) => {
                props.getTools({ setOpen });
               // setModalOpen = setOpen;
            }}>

            <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-denomination-sociale">
                    Denomination Social du client
                </label>
                <input
                    value={client.denominationSocial || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.denominationSocial = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-denomination-sociale" type="text" placeholder="ex: Citeo" />
            </div>

            <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name">
                    RCS
                </label>
                <input
                    value={client.rcs || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.rcs = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name" type="text" placeholder="ex: Ville 000 000 000" />
            </div>


            <div className="w-full  px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name">
                    Siret
                </label>
                <input
                    value={client.siret || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.siret = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name" type="text" placeholder="ex: 84074430400022" />
            </div>

            <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name">
                    President
                </label>
                <input
                    value={client.president || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.president = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name" type="text" placeholder="ex: jean mouloud de montalambere" />
            </div>
            <div className="w-full  px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name">
                    TVA intercom.
                </label>
                <input
                    value={client.tvaIntracom || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.tvaIntracom = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name" type="text" placeholder="ex: FR0000000000" />
            </div>


            <div className="w-full  px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name">
                    contact
                </label>
                <input
                    value={client.contact || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.contact = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name" type="text" placeholder="ex: moha begdadi" />
            </div>


            <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name">
                    contact phone
                </label>
                <input
                    value={client.contactPhone || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.contactPhone = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name" type="text" placeholder="ex: 0600000000" />
            </div>
            <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name">
                    contact email
                </label>
                <input
                    value={client.contactMail || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.contactMail = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name" type="text" placeholder="ex: user@exaple.com" />
            </div>

            <div className="w-full  px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name">
                    address
                </label>
                <input
                    value={client.address || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.address = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name" type="text" placeholder="ex:  12 rue de la reine Amelie" />
            </div>
            <div className="w-full px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name">
                    code postal
                </label>
                <input
                    value={client.zipCode || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.zipCode = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name" type="text" placeholder="ex: 94000" />
                <p className="text-gray-600 text-xs italic">Code postal du client</p>

            </div>
            <div className="w-full  px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name">
                    ville
                </label>
                <input
                    value={client.city || ""}
                    required={true}
                    onChange={e => {
                        let em = { ...client };
                        em.city = e.target.value;
                        setClient(em);
                    }}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name" type="text" placeholder="ex: Bobiny" />
                <p className="text-gray-600 text-xs italic">Ville ou le client se situe</p>
            </div>

        </Modal>


    )
}