import React from "react";
import Page from "./Page";


class Messages extends React.Component {
    registerNotification() {
        Notification.requestPermission(permission => {
            if ( permission === "granted" ) {
                console.log("Log.")
            } else {

                console.error("Permission was not granted.");
            }
        });
    }

    render() {
        return <Page>
            <div>
            Messages COMMING SOON
            </div>
        </Page>
    }
}

export default Messages;