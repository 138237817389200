import React, {Fragment, useEffect, useState} from "react";
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, SelectorIcon} from "@webologie/react-icons/outline";
import SecuredLinkService from "../Services/SecuredLinkService";
import Dropzone from "dropzone";
import {Link, useLocation} from "react-router-dom";
import AuthManager from "../secu/AuthManager";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CraPrev(props) {
    let [link, setLink] = useState({
        Mission: {
            CraHistories: [{}],
            Consultant: {
                Utilisateur: {}
            }
        }

    })
    let location = useLocation()

    let [perime, setPerime] = useState(false);

    let SecuKey = location.pathname.split("/")[2]
    let service = new SecuredLinkService(SecuKey)
    useEffect(function () {
        // let myDropzone = new Dropzone("#zonzon", {
        //     url: "/api/cra",
        //     paramName: "cra",
        //     headers: {
        //         "Authorization": "bearer " + localStorage.getItem("token")
        //     }
        // });
        service.getInfos().then(json => {
            if (json.success) {
                setLink(json.data);
                console.log(json.data);
            } else {
                setPerime(true)
            }
        })
    }, [])

    let saveCra = () => {
        const formData = new FormData();
        formData.append('cra', cra);
        formData.append('id', link.Mission.id);
        formData.append('linkId', link.id);
        fetch("/api/cra", {
            method: "POST",
            body: formData,
            headers: {
                "Authorization": "bearer " + localStorage.getItem("token")
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status == 401) {
                    AuthManager.desAuthenticateUser();
                    window.location.href = '/login';
                }

            })
            .then(json => {
                console.log(json)
                setUploaded(true);
            })
            .catch(err => {
                console.error(err)
            });

    }
    let openCraChoser = () => {
        console.log("OPenening file choser");
        var x = document.createElement("input");
        x.setAttribute("type", "file");
        x.accept = "application/pdf,image/*";
        x.style.display = "none";
        document.body.appendChild(x);
        x.addEventListener('change', (e) => {
            setCra(x.files[0]);
            x.parentNode.removeChild(x);
        });
        x.click();
    }
    const [uploaded, setUploaded] = useState(false)

    const [cra, setCra] = useState(null)
    let getFileSelectedView = () => {
        if (cra) {
            return <div className="space-y-1 text-center">
                <p>Vous avez selectionner : </p>
                <p>Nom : {cra.name}</p>
                <p>Poid : {(cra.size / 1000 / 1000).toFixed(2)} Mo</p>
            </div>
        }

    }
    let {firstname, lastname} = link.Mission.Consultant.Utilisateur;
    if (uploaded) {

        return <div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <img src="/img/milogo.png"></img>

            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "400px"}}>
                <div className={"text-center"}>
                    <p> chargement réussi, si vous voulez voulez charger un autre fichier, vous devez vou connecter a votre compte</p>
                    {getFileSelectedView()}
                    <br/>
                    <Link className={"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"}
                          to={"/login"}> Je me connect à mon profile</Link>
                </div>

            </div>
        </div>;

    }

    if (perime) {
        return <div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <img src="/img/milogo.png"></img>

            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "400px"}}>
                <div className={"text-center"}>
                    <p> Vous ne pouvez plus utiliser ce liens, pour charger votre compte
                        rendu d'activités, Ce liens est périmé</p>
                    <br/> <br/>
                    <p>Veuillez vous connecter a votre profile, pour charger Un nouveau compte
                        rendu d'activités <br/> <br/><Link
                        className={"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"}
                        to={"/login"}> Je me connect à mon profile</Link></p>

                </div>
            </div>
        </div>
    } else {
        return <>
            <div className="container mx-auto px-4 w-50">
                <div style={{display: "flex", justifyContent: "center"}}>
                    <img src="/img/milogo.png"></img>
                </div>
                <div className="text-xl font-medium text-black text-center mt-5 mb-5">Veuillez charger votre compte
                    rendu d'activités du : {link.Mission.CraHistories[0].mounth}-{link.Mission.CraHistories[0].year}</div>

                {/* <div className="text-xl font-medium text-black text-center mb-5">{JSON.stringify(link, null, 4)}</div> */}
                <div className="text-xl font-medium text-black text-center mb-5">{firstname} {lastname}</div>
                <div className="text-xl font-medium text-black text-center mb-5">{link.Mission.title}</div>

                <label className="block text-sm font-medium text-gray-700">Compte rendu d'activités</label>
                <div id="zonzon"
                     className="mt-1 flex justify-center flex-col px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
                     onClick={openCraChoser}>

                    {getFileSelectedView()}

                    <div className="space-y-1 text-center">
                        <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span
                            className=" cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >Charger Votre CRA.</span>
                        {/* <p className="pl-1">ou drag and drop</p> */}
                    </div>
                    <p className="text-xs text-gray-500 text-center">PNG, JPG, PDFTaille max 10MB</p>
                </div>


                <div className="pt-6 text-right">
                    <button
                        className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={saveCra}
                    >
                        Valider mes informations
                    </button>
                </div>


            </div>
        </>;
    }

}
