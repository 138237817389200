import React from "react"



export default function FileSelector(props) {


    let openFileChoser = ()=> {
        var x = document.createElement("input");
        x.setAttribute("type", "file");
        x.accept = "application/pdf";
        x.style.display = "none";
        document.body.appendChild(x);
        x.addEventListener('change', (e) => {
            (props.onchose && props.onchose(x.files[0]));
            x.parentNode.removeChild(x);
        });
        x.click();
    }

    return <>
        <button
            style={{ height: "46px" }}
            className="w-full flex items-center justify-between bg-green-500  mt-3 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-3 rounded outline-none focus:outline-none  ease-linear transition-all duration-150"
            type="button"
            onClick={openFileChoser}
        >
            {props.label||"veillez selectionner un fichier"} &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>

        </button>
        {props.helper && <p className="text-gray-500 text-xs italic">{props.helper}</p>}
    </>;


}

FileSelector.defaultProps = {
}