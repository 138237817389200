import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "./Page";
import AdminsService from "../Services/AdminsService";
import AdminGrid from "../mini/AdminGrid";

export default function AdminsCrud() {
    const navigate = useNavigate();
    let [ admins, setAdmins] = useState([])
    let service = new AdminsService();
    useEffect(() => {
      /*  db.Consultant.toArray().then(array=>{
            setConsultants(array)
        })*/
        service.getAll().then(json => {
            if ( json.success ) {
                setAdmins(json.data);
            }
        })
    }, [])

    let deleteConsultant = (consultant) => {
        if(confirm("Etes vous sur de vouloir supprimer "+consultant.firstname+' '+consultant.lastname)) {

            service.delete(consultant.id).then(json => {
                if (json.success) {
                    let e = admins
                    e = e.filter((em) => {
                        return em.id != consultant.id
                    })
                    setAdmins(e)
                }
            })
        }
    }


    return <Page>
        <div className="pt-2 sm:px-6 lg:px-8">
            <button
                className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={e => {
                    navigate('/admins/create')
                }}
            >
                Nouvel Administrateur
            </button>
        </div>
        <AdminGrid
            admins={admins}
            onEdit={consultant => {
                navigate("/admins/create/"+consultant.id)
                //  console.log("editing", consultant)
            }}
            onDelete={deleteConsultant}
        />
    </Page>;
}
