import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Page from "./Page";
import EmployeurGrid from "../mini/EmployeurGrid";
import EmployeurService from "../Services/EmployeurService";

export default function Employeurs() {
    const navigate = useNavigate();
    let [employeurs, setEmployeurs] = useState([])

    let service = new EmployeurService();

    useEffect(() => {
        service.getAll().then(json => {
            if (json.success) {
                setEmployeurs(json.data);
            }
        })
    }, [])

    let deleteEmployeur = (employeur) => {
        if (confirm("Etes vous sur de vouloir supprimer " + employeur.denominationSocial)) {
            service.delete(employeur.id).then(json => {
                if (json.success) {
                    let e = employeurs
                    e = e.filter((em) => {
                        return em.id != employeur.id
                    })
                    setEmployeurs(e)
                }
            })
        }

    };
    return <Page>
        <div className="pt-2 sm:px-6 lg:px-8">
            <button
                className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={e => {
                    navigate('/employeurs/create')
                }}
                data-cy={"New-Employeur-Btn"}

            >
                
                Nouvel Employeur
            </button>
        </div>
        <EmployeurGrid onEdit={e => {
            navigate('/employeurs/create/' + e.id);
        }}
        employeurs={employeurs}
        onDelete={deleteEmployeur}/>
    </Page>;
}
