import React, { useContext, useEffect, useState } from 'react';
import AuthenticationContext from "../secu/AuthenticationContext";
import ConsultantService from '../Services/ConsultantService';
import { CAChart } from './Charts/CAChart';
import FileUploaderRow from './FileUploaderRow';

export default function ConsultantsDash() {
    const authContext = useContext(AuthenticationContext);

    let connected = authContext.getAuthenticationData();

    let service = new ConsultantService();

    let getCAChart = () => {
        return <form className="flex flex-col justify-center p-10">
            <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>CA des 12 derniers mois:   {connected.firstname} {connected.lastname}</h3>
            <div className="flex flex-wrap -mx-3 mb-6">
                <CAChart></CAChart>
            </div>
        </form>

    }
    let [docs1, setDocs1] = useState({
        cv: {},
        pi: {},
        jd: {},
        ass: {},
        cg: {},
        cm: {},
        ap: {},
        ib: {}
    })
    let getConsultantInfosForm = () => {
        return <form className="flex flex-col justify-center p-10">

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Infos du consultant: {connected.firstname} {connected.lastname}</h3>

            </div>


            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-first-name">
                        Nom
                    </label>
                    <div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{connected.firstname || "pas de nom"}</div>

                </div>

                <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name">
                        Prenom
                    </label>
                    <div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{connected.lastname || "pas de prenom"}</div>

                </div>
            </div>



            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 md:w-1/2">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-email">
                        email
                    </label>
                    <div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{connected.login || "pas d'email "}</div>

                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-city">
                        Telephone
                    </label>
                    <div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{connected.phone || " Aucun telephone "}</div>



                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 md:w-1/2">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-email">
                        Date de naissance
                    </label>
                    <div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{connected.Consultant.birthDate && (new Date(connected.Consultant.birthDate)).toLocaleDateString() || "pas de date de naissance"}</div>


                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-city">
                        Numéro de sécurité sociale
                    </label>
                    <div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{connected.Consultant.secuNum || "pas de numero de securite sociale'"}</div>


                </div>
            </div>



            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 md:w-1/2">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-email">
                        Adresse complète
                    </label>
                    <div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{connected.Consultant.address || "pas d'adresse'"}</div>
                </div>
                <div className="w-full px-3 md:w-1/4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-email">
                        Code postal
                    </label>
                    <div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{connected.Consultant.zipCode || "pas de code postal'"}</div>


                </div>
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-city">
                        Ville
                    </label>
                    <div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{connected.Consultant.city || "pas de Ville"}</div>
                </div>
            </div>
        </form>

    }

    let downloadDoc = (doc) => {
        service.downloadDocs1(doc).then(() => {
            console.log("DOWNLOAD", doc)
        });
    };
    useEffect(() => {

        service.readDocs1(connected.Consultant.id).then((json => {
            setDocs1(json.data)
        }))

    }, []);
    let getConsultantDocsForm = () => {
        return <form className="flex flex-col justify-center p-10">

            <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Documents d'identité du consultant: {connected.firstname} {connected.lastname}</h3>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="docLine">
                    <span>Document</span>
                    <span>Nom du fichier</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
                <FileUploaderRow
                    readOnly={true}
                    label="CV"
                    ondownload={downloadDoc}
                    doc={docs1.cv}></FileUploaderRow>

                <FileUploaderRow
                    readOnly={true}
                    female={true}
                    label="Pièce d'identité"
                    ondownload={downloadDoc}
                    doc={docs1.pi}></FileUploaderRow>
                <FileUploaderRow
                    readOnly={true}
                    label="Justificatif de domicile"
                    ondownload={downloadDoc}
                    doc={docs1.jd}></FileUploaderRow>
                <FileUploaderRow
                    readOnly={true}
                    female={true}
                    label="Attestation de sécurité sociale"
                    ondownload={downloadDoc}
                    doc={docs1.ass}></FileUploaderRow>
                <FileUploaderRow
                    readOnly={true}
                    female={true}
                    label="Carte grise"
                    ondownload={downloadDoc}
                    doc={docs1.cg}></FileUploaderRow>
                <FileUploaderRow
                    readOnly={true}
                    female={true}
                    label="Information bancaire"
                    ondownload={downloadDoc}
                    doc={docs1.ib}></FileUploaderRow>

            </div>


        </form>
    }

    let getConsultantHealthDocsForm = () => {
        return <form className="flex flex-col justify-center p-10">



            <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Documents de Santé du consultant: {connected.firstname} {connected.lastname}</h3>


            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="docLine">
                    <span>Document</span>
                    <span>Nom du fichier</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
                <FileUploaderRow label="Contrat mutuelle"
                    readOnly={true}
                    ondownload={downloadDoc}
                    doc={docs1.cm}></FileUploaderRow>
                <FileUploaderRow label="Attestation de prévoyance"
                    readOnly={true}
                    ondownload={downloadDoc}
                    doc={docs1.ap}></FileUploaderRow>

            </div>


        </form>

    }

    return <>
        <div className={"formFull grid grid-cols-2 gap-4 divide-x divide-gray-200 border-b-2 border-gray-200 px-12"}>
            <div role="Form" className={"col-span-2"}>

                <div className="py-5 flex justify-center text-2xl">
                    <h3>My-intranet - Bienvenu {connected.firstname} {connected.lastname}</h3>
                </div>
                {getCAChart()}
                {getConsultantInfosForm()}
                {getConsultantDocsForm()}
                {getConsultantHealthDocsForm()}
            </div></div>


    </>;

}