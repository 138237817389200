import React, { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthManager from "../secu/AuthManager";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr"; // the locale you want
registerLocale("fr", fr);
import "./react-datepicker.scss";
import ConsultantService from "../Services/ConsultantService";
import Field from "./mini/Field";
import Page from "../pages/Page";
import FileUploaderRow from "../mini/FileUploaderRow";
import { CAChart } from "../mini/Charts/CAChart";
import MissionTabs from "../mini/MissionTabs";


export default function ConsultantFullForm(props) {

    const navigate = useNavigate();
    let location = useLocation()
    let params = useParams()
    let consultantToEdit = location.state;
    let [docs1ro, setDocs1ro] = useState(true);
    let [docs2ro, setDocs2ro] = useState(true);
    let [infosro, setInfosro] = useState(true);

    let submitForm = function () {
        console.log(consultant);
        if (params && params.id) {
            //je suis en modif

            service.update(consultant).then(json => {
                if (json.success) {
                    setInfosro(true)
                    console.log(json)
                    // navigate("/consultant");
                }
            })
        } else {
            //je suis en ajout
            fetch("/api/consultant", {
                method: "POST",
                body: JSON.stringify(consultant),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                })
                .then(json => {
                    if (json.success) {
                        navigate("/consultant");
                    } else {
                        alert(json.message)
                    }
                })
                .catch(err => {
                    console.error(err)
                });
        }


    }
    let [consultant, setConsultant] = useState(consultantToEdit || {
        firstname: "",
        lastname: "",
        login: '',
        Utilisateur: {},
        Missions: {
            Clients: [],
            Employeurs: []
        }
    });

    let [docs1, setDocs1] = useState({
        cv: {},
        pi: {},
        jd: {},
        ass: {},
        cg: {},
        cm: {},
        ap: {},
        ib: {}
    })
    let service = new ConsultantService();
    useEffect(() => {

        service.readDocs1(consultant.id).then((json => {
            setDocs1(json.data)
        }))
        
    }, []);






    let getConsultantInfosForm = () => {
        return <form className="flex flex-col justify-center p-10">

            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Infos du consultant: {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h3>
                <button
                    className="bg-blue-500 active:bg-blue-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        setInfosro(!infosro);
                    }}
                    data-cy={"consultant-add-btn"}

                >{infosro ? "Modifier" : "Annuler"}</button>
            </div>


            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-first-name">
                        Nom
                    </label>
                    {infosro && <><div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.Utilisateur.firstname || "pas de nom"}</div></>}
                    {!infosro && <><input
                        readOnly={infosro}
                        data-cy={"consultant-firstname"}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        required={true}
                        value={consultant.Utilisateur.firstname || ""}
                        onChange={e => {
                            let c = { ...consultant };
                            c.Utilisateur.firstname = e.target.value;
                            setConsultant(c);
                        }}
                        type="text"
                        placeholder="ex: Dupont" />
                        <p className="text-red-500 text-xs italic">Please fill out this field.</p></>}
                </div>

                <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-last-name">
                        Prenom
                    </label>
                    {infosro && <><div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.Utilisateur.lastname || "pas de prenom"}</div></>}
                    {!infosro && <input
                        id="grid-last-name"
                        data-cy={"consultant-lastname"}

                        value={consultant.Utilisateur.lastname || ""}
                        required={true}
                        onChange={e => {
                            let c = { ...consultant };
                            c.Utilisateur.lastname = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text" placeholder="ex: Romain" />}
                </div>
            </div>



            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 md:w-1/2">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-email">
                        email
                    </label>
                    {infosro && <><div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.Utilisateur.login || "pas d'email "}</div></>}

                    {!infosro && <><input
                        id="grid-last-email"
                        data-cy={"consultant-email"}

                        required={true}
                        value={consultant.Utilisateur.login || ""}
                        onChange={e => {
                            let c = { ...consultant };
                            c.Utilisateur.login = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="email" placeholder="ex: user@exaple.com" />
                        <p className="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p></>}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-city">
                        Telephone
                    </label>
                    {infosro && <><div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.Utilisateur.phone || " Aucun telephone "}</div></>}

                    {!infosro && <><input
                        data-cy={"consultant-phone"}

                        required={true}
                        value={consultant.Utilisateur.phone || ""}
                        onChange={e => {
                            let c = { ...consultant };
                            c.Utilisateur.phone = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-city"
                        type="number"
                        pattern={"[0-9]"}
                        step="0.01"
                        placeholder="0600000000" />
                        <p className="text-gray-600 text-xs italic">Tjm en €</p></>}

                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 md:w-1/2">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-email">
                        Date de naissance
                    </label>
                    {infosro && <><div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.birthDate && (new Date(consultant.birthDate)).toLocaleDateString() || "pas de date de naissance"}</div></>}
                    {!infosro && <DatePicker
                        data-cy={"consultant-birth-date"}

                        dateFormat="dd/MM/yyyy"
                        locale="fr"
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-green-500 px-4 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                        id="grid-birth-date"
                        selected={Date.parse(consultant.birthDate)}
                        onChange={e => {
                            console.log(e)
                            let c = { ...consultant };
                            c.birthDate = e;
                            setConsultant(c);
                        }} />}

                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-city">
                        Numéro de sécurité sociale
                    </label>
                    {infosro && <><div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.secuNum || "pas de numero de securite sociale'"}</div></>}

                    {!infosro && <input
                        data-cy={"consultant-Secu"}

                        required={true}
                        value={consultant.secuNum || ""}
                        onChange={e => {
                            let c = { ...consultant };
                            c.secuNum = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-city"
                        placeholder="0600000000" />}

                </div>
            </div>



            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 md:w-1/2">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-email">
                        Adresse complète
                    </label>
                    {infosro && <><div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.address || "pas d'adresse'"}</div></>}

                    {!infosro && <input
                        data-cy={"consultant-addr"}

                        required={true}
                        value={consultant.address || ""}
                        onChange={e => {
                            let c = { ...consultant };
                            c.address = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-email" type="email" placeholder="ex: 10 rue de la paix" />}
                </div>
                <div className="w-full px-3 md:w-1/4">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-email">
                        Code postal
                    </label>
                    {infosro && <><div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.zipCode || "pas de code postal'"}</div></>}

                    {!infosro && <Field
                        vtype="codePost"
                        data-cy={"consultant-cp"}

                        required={true}
                        value={consultant.zipCode || ""}
                        onChange={e => {
                            let c = { ...consultant };
                            c.zipCode = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-email" type="email" placeholder="ex: 78320" />}
                </div>
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-city">
                        Ville
                    </label>
                    {infosro && <><div className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.city || "pas de Ville"}</div></>}

                    {!infosro && <Field
                        data-cy={"consultant-city"}
                        required={true}
                        value={consultant.city || ""}
                        onChange={e => {
                            let c = { ...consultant };
                            c.city = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-city"
                        type="text"
                        pattern={"[0-9]"}
                        step="0.01"
                        placeholder="Ex: Poissy" />}

                </div>
            </div>


            {!infosro && <div className="pt-5 flex justify-between">
                <button
                    className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        // navigate("/consultant");
                        setInfosro(true)

                    }}
                >Annuler
                </button>
                <button
                    className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        submitForm();
                        //console.log(consultant);
                    }}
                    data-cy={"consultant-add-btn"}

                >
                    {(params && params.id) && "Valider"}
                    {!(params && params.id) && "Créer"}
                </button>
            </div>}

        </form>

    }
    let consultantDocsFormValidation = (e) => {

        let formData = new FormData();
        formData.append("cid", consultant.id);
        Object.keys(docs1).forEach((doctype) => {
            formData.append(doctype, docs1[doctype]);
        })
        service.sendDocs1(formData).then(() => {
            if (e.target.dataset.role == "doc1") {
                setDocs1ro(true);
            } else {
                setDocs2ro(true);
            }
        })
    }
    let downloadDoc = (doc) => {
        service.downloadDocs1(doc).then(() => {
            console.log("DOWNLOAD", doc)
        });
    };
    let getConsultantDocsForm = () => {
        return <form className="flex flex-col justify-center p-10">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Documents d'identité du consultant: {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h3>
                <button
                    className="bg-blue-500 active:bg-blue-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        //console.log(consultant);
                        setDocs1ro(!docs1ro);
                    }}
                    data-cy={"consultant-add-btn"}

                >{docs1ro ? "Modifier" : "Annuler"}</button>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="docLine">
                    <span>Document</span>
                    <span>Nom du fichier</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
                <FileUploaderRow
                    readOnly={docs1ro}
                    label="CV"
                    ondownload={downloadDoc}
                    onpreview={(doc) => {
                        // service.previewDoc(doc)
                        console.log("PREWIEW", doc)
                    }}
                    onChange={(file, e) => {
                        let docs = { ...docs1 }
                        docs.cv = file;
                        setDocs1(docs);
                    }}
                    doc={docs1.cv}></FileUploaderRow>

                <FileUploaderRow
                    readOnly={docs1ro}
                    female={true}
                    label="Pièce d'identité"
                    ondownload={downloadDoc}

                    onChange={(file, e) => {
                        let docs = { ...docs1 }
                        docs.pi = file;
                        setDocs1(docs);
                    }}
                    doc={docs1.pi}></FileUploaderRow>
                <FileUploaderRow
                    readOnly={docs1ro}
                    label="Justificatif de domicile"
                    ondownload={downloadDoc}

                    onChange={(file, e) => {
                        let docs = { ...docs1 }
                        docs.jd = file;
                        setDocs1(docs);
                    }}
                    doc={docs1.jd}></FileUploaderRow>
                <FileUploaderRow
                    readOnly={docs1ro}
                    female={true}
                    label="Attestation de sécurité sociale"
                    ondownload={downloadDoc}

                    onChange={(file, e) => {
                        let docs = { ...docs1 }
                        docs.ass = file;
                        setDocs1(docs);
                    }}
                    doc={docs1.ass}></FileUploaderRow>
                <FileUploaderRow
                    readOnly={docs1ro}
                    female={true}
                    label="Carte grise"
                    ondownload={downloadDoc}

                    onChange={(file, e) => {
                        let docs = { ...docs1 }
                        docs.cg = file;
                        setDocs1(docs);
                    }}
                    doc={docs1.cg}></FileUploaderRow>
                <FileUploaderRow
                    readOnly={docs1ro}
                    female={true}
                    label="Information bancaire"
                    ondownload={downloadDoc}

                    onChange={(file, e) => {
                        let docs = { ...docs1 }
                        docs.ib = file;
                        setDocs1(docs);
                    }}
                    doc={docs1.ib}></FileUploaderRow>

            </div>
            {!docs1ro && <div className="pt-5 flex justify-between">
                <button
                    className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        // setDocs1({ cv: {}, pi: {}, jd: {}, ass: {}, cg: {}, ib: {} });
                        setDocs1ro(true);
                    }}
                >Annuler
                </button>
                <button
                    className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    data-role="doc1"

                    onClick={consultantDocsFormValidation}
                    data-cy={"consultant-add-btn"}>
                    {(params && params.id) && "Valider"}
                    {!(params && params.id) && "Créer"}
                </button>
            </div>}

        </form>
    }
    let getConsultantHealthDocsForm = () => {
        return <form className="flex flex-col justify-center p-10">


            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Documents de Santé du consultant: {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h3>
                <button
                    className="bg-blue-500 active:bg-blue-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        setDocs2ro(!docs2ro);
                    }}
                    data-cy={"consultant-add-btn"}

                >{docs2ro ? "Modifier" : "Annuler"}</button>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="docLine">
                    <span>Document</span>
                    <span>Nom du fichier</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
                <FileUploaderRow label="Contrat mutuelle"
                    readOnly={docs2ro}

                    ondownload={downloadDoc}
                    onChange={(file, e) => {
                        let docs = { ...docs1 }
                        docs.cm = file;
                        setDocs1(docs);
                    }}
                    doc={docs1.cm}></FileUploaderRow>
                <FileUploaderRow label="Attestation de prévoyance"
                    readOnly={docs2ro}
                    ondownload={downloadDoc}
                    onChange={(file, e) => {
                        let docs = { ...docs1 }
                        docs.ap = file;
                        setDocs1(docs);
                    }}
                    doc={docs1.ap}></FileUploaderRow>
                {/* <FileUploaderRow></FileUploaderRow> */}

            </div>
            {!docs2ro && <div className="pt-5 flex justify-between">
                <button
                    className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        setDocs2ro(true);
                    }}
                >Annuler
                </button>
                <button
                    className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    data-role="doc2"
                    onClick={consultantDocsFormValidation}
                    data-cy={"consultant-add-btn"}
                >
                    {(params && params.id) && "Valider"}
                    {!(params && params.id) && "Créer"}
                </button>
            </div>}

        </form>

    }
    let getCAChart = () => {
        return <form className="flex flex-col justify-center p-10">
            <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>CA des 12 derniers mois:  {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h3>
            <div className="flex flex-wrap -mx-3 mb-6">

                <CAChart></CAChart>

            </div>
        </form>

    }


    let getMissionView = () => {
        return <>
            <form className="p-10">
                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Missions:  {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h3>
                    <MissionTabs consultant={consultant}></MissionTabs>
            </form></>
    }
    return <Page>
        <div className={"formFull grid grid-cols-2 gap-4 divide-x divide-gray-200 border-b-2 border-gray-200 px-12"}>
            <div role="Form" className={"col-span-2"}>
                {getConsultantInfosForm()}
                {getConsultantDocsForm()}
                {getConsultantHealthDocsForm()}
                {getCAChart()}
                {getMissionView()}
            </div>
        </div>
    </Page>
}