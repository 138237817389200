import React, { useState, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthenticationContext from "../secu/AuthenticationContext";
import Field from "../forms/mini/Field";
import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';
// const MySwal = withReactContent(Swal);
export default function Login() {
    const authContext = useContext(AuthenticationContext);
    const navigate = useNavigate();
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [errorMessage, setErrorMessage] = useState(null);
    let [message, setMessage] = useState(null);
    let [remember, setRemember] = useState(false);
    let emailF = null;
    let passwordF = null;
    let getErrorMessageView = () => {
        if (errorMessage && errorMessage != "" && errorMessage != null) {
            return <div className="bg-red-200 rounded px-3 py-3 text-center mb-3 font-bold">
                <small>{errorMessage}</small>
            </div>;
        }

    };
    let getMessageView = () => {
        if (message && message != "" && message != null) {
            return <div className="bg-lightBlue-200 rounded px-3 py-3 text-center mb-3 font-bold">
                <small>{message}</small>
            </div>;
        }
    };
    let login = () => {
        setMessage("My intranet authentification ....")
        if (emailF.isValid() && passwordF.isValid()) {
            authContext.login(email, password).then(json => {
                navigate('/');
            }).catch(err => {
                Swal.fire({
                    icon: 'error',
                    text: err.message,
                    // footer: '<a href="/login">Reessayer de me connecter?</a>'
                  })
                setMessage(null)
                setErrorMessage(err.message)
            })
        } else {
            setMessage(null)
            setErrorMessage("les infos saisies ne sont pas coherantes");
        }
    };
    return (<>
        <div className="container mx-auto px-4 h-full flex item-center flex-col mt-20">
            <div className="flex content-center items-center justify-center h-full">
                <div className="w-full lg:w-6/12 px-4">
                    <div
                        className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-100 border-0">

                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-10">
                            {getErrorMessageView()}
                            {getMessageView()}
                            <form>
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Email
                                    </label>
                                    <Field
                                        data-cy={"email-login"}
                                        type="email"
                                        vtype="email"
                                        required={true}
                                        ref={e => emailF = e}
                                        enteraction={login}
                                        value={email}
                                        onChange={e => {
                                            setEmail(e.target.value);
                                        }}
                                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Email"
                                    />
                                </div>

                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-gray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Password
                                    </label>
                                    <Field
                                        type="password"
                                        vtype="password"
                                        data-cy={"password-login"}
                                        enteraction={login}
                                        value={password}
                                        ref={e => passwordF = e}
                                        onChange={e => {
                                            setPassword(e.target.value);
                                        }}
                                        required={true}
                                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Password"
                                    />
                                </div>
                                <div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            id="customCheckLogin"
                                            type="checkbox"
                                            checked={remember}
                                            onChange={e => {
                                                setRemember(e.target.checked);
                                            }}
                                            className="form-checkbox border-0 rounded text-gray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                                        />
                                        <span
                                            className={"ml-2 text-sm font-semibold text-gray-600"}>Remember me</span>
                                    </label>
                                </div>

                                <div className="text-center mt-6">
                                    <button
                                        className="bg-gray-300 active:bg-gray-600 text-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={login}
                                        data-cy={"btn-connect-login"}

                                    >
                                        Me connecter
                                    </button>
                                </div>
                            </form>
                            <div className="flex flex-wrap mt-6 relative">
                                <div className="w-full text-right">
                                    <Link
                                        to="/reset"
                                        className="text-blue-600"
                                    >
                                        <small>Forgot password?</small>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
    );
}
