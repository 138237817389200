import React, {Fragment, useEffect, useState} from "react";
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, SelectorIcon} from "@webologie/react-icons/outline";
import SecuredLinkService from "../Services/SecuredLinkService";
import PrevService from "../Services/PrevService";
import {Link, useLocation} from "react-router-dom";
import Swal from 'sweetalert2'

const options = [];
options.push({
    id: 'none',
    name: "Nombre de jours ?",
})
for (let i = 0; i <= 230; i += 5) {
    options.push({
        id: i,
        name: i / 10,
    })
}


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Prev(props) {
    let [user, setUser] = useState({
        Consultant: {
            Missions: [{}]
        }
    })
    let [done, setDone] = useState(false);

    let [perime, setPerime] = useState(false);
    let [link, setLink] = useState({
        Mission: {
            PrevHistories: [{}],
            Consultant: {
                Utilisateur: {}
            }
        }

    })
    let location = useLocation();
    let SecuKey = location.pathname.split("/")[2]
    let service = new SecuredLinkService(SecuKey)
    let mservice = new PrevService()
    useEffect(function () {
        service.getInfos().then(json => {
            if (json.success) {
                setLink(json.data);
                console.log(json.data);
            } else {
                setPerime(true)
            }

        });
        // mservice.findByLink()
    }, [])

    let savePrev = () => {
        Swal.fire({
            title: `Validation de saisie ?`,
            text: `Vous avez saisie ${selectedPrev.name} jours, Veillez confirmer cette saisie?
             La modification de ce previsionmnel se fera depuis votre profile`,
            icon: 'warning',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: `Oui, je valide ${selectedPrev.name}j`,
            cancelButtonText: "Non, je modifie mon previsionnel !",
            closeOnConfirm: false
        }).then((res) => {
            if (res.isConfirmed) {
                // let m = user.Consultant.Missions[0];
                let data = {
                    id: link.Mission.id,
                    nbJours: selectedPrev.name,
                    linkId: link.id
                };
                // console.log(data);
                mservice.update(data).then(json => {
                    setDone(json.success)
                    console.log(selectedPrev.name)
                })
            }
        })
    }
    const [selectedPrev, setSelectedPrev] = useState(options[0])
    let getSelectDays = function () {

        return (<>
                <div className="pb-3">
                    <Listbox value={selectedPrev} onChange={setSelectedPrev}>
                        {({open}) => (
                            <>
                                <Listbox.Label className="block text-sm font-medium text-gray-700">Nombre de jours
                                    travaille</Listbox.Label>
                                <div className="mt-1 relative">
                                    <Listbox.Button
                                        className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                    <span className="flex items-center">
                                        <span className="ml-3 block truncate">{selectedPrev.name}</span>
                                    </span>
                                        <span
                                            className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options
                                            className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            {options.map((person) => (
                                                <Listbox.Option
                                                    key={person.id}
                                                    className={({active}) =>
                                                        classNames(
                                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                            'cursor-default select-none relative py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={person}
                                                >
                                                    {({selected, active}) => (
                                                        <>
                                                            <div className="flex items-center">
                                                            <span
                                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                            >
                                                                {person.name}
                                                            </span>
                                                            </div>

                                                            {selected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active ? 'text-white' : 'text-indigo-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                            </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
                <div className="pt-6 text-right">
                    <button
                        className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={savePrev}
                    >
                        Valider
                    </button>
                </div>
            </>

        )
    }
    let {firstname, lastname} = link.Mission.Consultant.Utilisateur;
    if (done) {
        return <div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <img src="/img/milogo.png"></img>

            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "400px"}}>
                <div className={"text-center"}>
                    <p> Saisie réussi. Vous avez saisie : {selectedPrev.name} Jours</p>
                    <br/>
                    <br/>
                    <Link className={"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"}
                          to={"/login"}> Je me connect à mon profile</Link>
                </div>

            </div>
        </div>
    }
    if (perime ) {
        return <div>
            <div style={{display: "flex", justifyContent: "center"}}>
                <img src="/img/milogo.png"></img>

            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "400px"}}>
                <div className={"text-center"}>
                    <p> Vous ne pouvez plus utiliser ce liens, pour saisir votre prévisionnel, Ce liens est périmé</p>
                    <br/> <br/>
                    <p>Veuillez vous connecter a votre profile, pour saisir Un nouveau prévisionnel <br/> <br/><Link
                        className={"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"}
                        to={"/login"}> Je me connect à mon profile</Link></p>

                </div>
            </div>
        </div>
    } else {
        return <>
            <div className="container mx-auto px-4 w-50">
                <div style={{display: "flex", justifyContent: "center"}}>
                    <img src="/img/milogo.png"></img>
                </div>
                <div className="text-xl font-medium text-black text-center mt-5 mb-5">Veuillez saisir votre
                    previsionnel du : {link.Mission.PrevHistories[0].mounth}-{link.Mission.PrevHistories[0].year}</div>

                {/* <div className="text-xl font-medium text-black text-center mb-5">{JSON.stringify(link, null, 4)}</div> */}
                <div className="text-xl font-medium text-black text-center mb-5">{firstname} {lastname}</div>
                <div className="text-xl font-medium text-black text-center mb-5">{link.Mission.title}</div>
                {getSelectDays()}
                {/* {link.Mission.PrevHistories[0] && link.Mission.PrevHistories[0].nbJours == null && getSelectDays()} */}
            </div>
        </>;
    }

}
