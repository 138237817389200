import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "./Page";
import MissionGrid from "../mini/MissionGrid";
import MissionsService from "../Services/MissionsService";


export default function Missions() {
    let navigate = useNavigate();
    let [missions, setMissions] = useState([]);
    let service = new MissionsService();
    useEffect(() => {
        service.getAll().then(json => {
            if (json.success) {
                setMissions(json.data);
            }
        })
    }, [])
    let editMission = (mission) => {
        navigate('/missions/update', { state: mission })
        console.log("editing", mission)
    }


    let showMissionDocs = (mission) => {
        navigate('/missions/docs', mission)

    }

    let deleteMission = (mission) => {
        if (confirm("Êtes-vous sûr de vouloir  supprimer définitivement cette mission ?")) {
            service.delete(mission.id).then(jsonSrv => {
                console.log("Deleted", jsonSrv);
                let m = missions.filter((missionInState) => {
                    return missionInState.id != mission.id;
                });
                setMissions(m)

            })
        }

    };
    return <Page>
        <div className="pt-2 sm:px-6 lg:px-8">
            {/* <button
                className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={e => {
                    history.push('/missions/create')
                }}
            >
                Nouvelle Mission
            </button> */}
        </div>
        <MissionGrid onDocument={showMissionDocs} missions={missions} onEdit={editMission} onDelete={deleteMission}></MissionGrid>
    </Page>;
}
