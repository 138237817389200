import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConsultantService from "../Services/ConsultantService";
import ConsultantGrid from "../mini/ConsultantGrid";
import Page from "./Page";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function Consultants() {
    const navigate = useNavigate();
    let [consultants, setConsultants] = useState([])
    let service = new ConsultantService();
    useEffect(() => {
        service.getAll().then(json => {
            if (json.success) {
                setConsultants(json.data);
            }
        })
    }, [])

    let deleteConsultant = (consultant) => {
        MySwal.fire({
            title: `Suppression de ${consultant.Utilisateur.firstname} ${consultant.Utilisateur.lastname} ?`,
            text: "Êtes-vous sûr de vouloir  supprimer définitivement Le consultant!",
            type: "error",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Oui, je supprime !",
            cancelButtonText: "Non, je ne supprime pas !",
            closeOnConfirm: false
        }).then((res) => {
            if (res.isConfirmed) {
                service.delete(consultant.id).then(json => {
                    if (json.success) {
                        let e = consultants
                        e = e.filter((em) => {
                            return em.id != consultant.id
                        })
                        setConsultants(e)
                    }
                })
            }
        })

    };

    let openMissionForm = (consultant) => {
        navigate('/missions/create', { state: consultant })
    };

    return <Page>
        <div className="pt-2 sm:px-6 lg:px-8">
            <button
                className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={e => {
                    navigate('/consultant/create')
                }}
                data-cy={"New-Consultant-Btn"}
            >
                Nouveau Consultant
            </button>
        </div>
        <ConsultantGrid
            consultants={consultants}
            onmission={openMissionForm}
            onEdit={consultant => {
                navigate("/consultant/create/" + consultant.id, { state: consultant })
            }}
            onDelete={deleteConsultant}
        />
    </Page>;
}
