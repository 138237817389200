class AuthManager {
  static Hooks = {
    login: {},
    logout: {}
  };
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */
  static authenticateUser(User) {
    this.setAuthenticationData(User);
  //  document.cookie = "Bearer="+User.token;
    Object.keys(AuthManager.Hooks.login).forEach(key => {
      let fn = AuthManager.Hooks.login[key];
      fn(User);
    });
  }

  static setAuthenticationData(User) {
    localStorage.setItem("userData", JSON.stringify(User));
    localStorage.setItem("access_token", User.token);
  }
  static setAuthenticationEmail(email) {
   let u = this.getAuthenticationData();
    u.login = email;
    this.setAuthenticationData(u);
  }

  static getAuthenticationData() {
    try {
      let u = localStorage.getItem("userData");
      if (u == null || u == undefined || u.err != undefined) {
        this.desAuthenticateUser();
      }
      return JSON.parse(u);
    } catch (error) {
      this.desAuthenticateUser();
    }
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    try {
      return localStorage.getItem("access_token") !== null && localStorage.getItem("access_token") !== undefined;
    } catch (error) {
      this.desAuthenticateUser();
    }
  }
  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static desAuthenticateUser() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userData");
   // document.cookie = "Bearer=undefined";
    Object.keys(AuthManager.Hooks.logout).forEach(key => {
      let fn = AuthManager.Hooks.logout[key];
      fn();
    });
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    return localStorage.getItem("access_token");
  }
}
export default AuthManager;
